<template>
    <div class="container-fluid w-75" v-if="report">
        <div class="text-right mb-2">
            <button class="btn btn-info" @click="printReport">印刷</button>
        </div>
        <CCard id="printable">
            <CCardBody class="print">
                <div class="row">
                    <div class="col-12">
                        <h3 class="text-center">医療連携看護指示書</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="text-left h5">指示施設名：
                            {{ report.relationships.person.relationships.facility.attributes.name }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 h5">
                        <p class="text-right">(訪問看護指示期間：{{ report.attributes.start_date | moment('YYYY年MM月DD日') }}より6ヶ月)
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <table class="table table-bordered h5">
                            <tbody>
                            <tr>
                                <td colspan="4">患者氏名：{{ report.relationships.person.attributes.name }}</td>
                                <td colspan="4">生年月日：{{ report.relationships.person.attributes.birthday | moment('YYYY年MM月DD日') }}</td>
                                <td colspan="4">年齢：{{ getAge(report.relationships.person.attributes.birthday) }}歳</td>
                            </tr>
                            <tr>
                                <td colspan="12">患者住所：{{ report.relationships.person.attributes.address }}</td>
                            </tr>
                            <tr>
                                <td colspan="3" class="label">主たる傷病名</td>
                                <td colspan="9">{{ report.attributes.illness }}</td>
                            </tr>
                            <tr>
                                <td rowspan="4" class="label status">現在の状況</td>
                                <td colspan="2" class="label">病状・治療状況</td>
                                <td colspan="9">{{ report.attributes.status }}</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="label h6">投与中の薬剤の用量・用法</td>
                                <td colspan="9">{{ report.attributes.usage }}</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="label">日常生活自立度</td>
                                <td colspan="9">{{ report.attributes.independence }}</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="label"><p>【看護理由】<br>医療連携の目的</p></td>
                                <td colspan="9">{{ report.attributes.reason }}</td>
                            </tr>
                            <tr>
                                <td colspan="12" class="text-center">医療連携に関する留意事項及び指示事項【看護内容等】</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">1</td>
                                <td colspan="11">{{ report.attributes.instruction1 }}</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">2</td>
                                <td colspan="11">{{ report.attributes.instruction2 }}</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">3</td>
                                <td colspan="11">{{ report.attributes.instruction3 }}</td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">4</td>
                                <td colspan="11" class="instruction4">
                                    <div class="instruction4-inner row d-flex">
                                        <p class="col-2 other">その他</p>
                                        <p class="col-10 parenthesis">{{ report.attributes.instruction4 }}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1" class="label">5</td>
                                <td colspan="2" class="align-middle">
                                    <p class="text-center">【必要回数】<br>連携に係る頻度</p>
                                </td>
                                <td colspan="9" class="align-middle" v-if="report.attributes.instruction5_choice !== 3">
                                    {{ getRequiredNumber(report.attributes.instruction5_choice) }}
                                </td>
                                <td colspan="9" class="align-middle" v-else>
                                    {{ report.attributes.instruction5 }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="label">特記すべき留意事項</td>
                                <td colspan="9">{{ report.attributes.remarks }}</td>
                            </tr>
                            <tr>
                                <td colspan="3" class="label">指定訪問事業所名</td>
                                <td colspan="9">
                                    {{ report.relationships.person.relationships.facility.relationships.office.attributes.name }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="row">
                            <div class="col-12">
                                <p class="text-left h5">上記の通り、医療連携看護の実施を指示いたします。</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-2">
                                <p class="text-right h5" v-if="report.attributes.regist_date">
                                    {{ report.attributes.regist_date | moment('YYYY年MM月DD日') }}
                                </p>
                                <div class="float-right d-flex h5" v-else>
                                    <span>年</span><span class="ml-4">月</span><span class="ml-4">日</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row bottom-block">
                    <div class="col-6"></div>
                    <div class="col-6 h5">
                        <p>医療機関名：{{ report.attributes.hospital_name }}</p>
                        <p>住所：{{ report.attributes.hospital_address }}</p>
                        <p>電話：{{ report.attributes.hospital_tel }}</p>
                        <p>(FAX)：{{ report.attributes.hospital_fax }}</p>
                        <p>医師氏名：{{ report.attributes.doctor_name }}</p>
                    </div>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import Helpers from "@/components/Systems/Helpers";

export default {
    name: "DisplayDoctorDirection",
    mixins: [ApiRouter, ErrorHandler, Helpers],
    data: function () {
        return {
            report: null
        }
    },
    created: function () {
        this.loadInstructionReport();
    },
    methods: {
        loadInstructionReport: function () {
            axios.get(this.routes.InstructionReport(this.$route.params['id']))
                .then(response => {
                    this.report = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        getRequiredNumber: function (instruction5Choice) {
            switch (instruction5Choice) {
                case 0:
                    return '週1回';
                case 1:
                    return '週2回';
                case 2:
                    return '週3回以上';
                default:
                    return '';
            }
        },
        printReport: function () {
            window.document.body.innerHTML = document.getElementById('printable').innerHTML;
            window.print();
            this.$router.go({path: this.$router.currentRoute.path, force: true});
        }
    }
}
</script>

<style src="./DisplayDoctorDirection.scss" lang="scss" scoped/>
